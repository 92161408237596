<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-12">
          <h5 style="color: blue; margin-bottom: 30px">
            {{ $t("menu.assiduite") }}
          </h5>
          <label>{{ $t("sms.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            $t("recette.date")
          }}</label>
          <a-date-picker
            style="width: 180px; margin-left: 5px"
            format="DD/MM/YYYY"
            v-model="selectedDate"
            @change="handleDateChange"
            :disabledDate="(current) => current > moment()"
            v-decorator="['start', { rules: [{ required: true }] }]"
          />

          <label style="margin-right: 5px; margin-left: 15px">{{
            $t("emploi.seance")
          }}</label>
          <a-select
            :placeholder="$t('emploi.seanceListe')"
            style="width: 180px; margin-left: 5px; margin-bottom: 10px"
            :loading="sessionLoading"
            v-model="selectedSession"
            @change="
              (val) => {
                this.selectedSession = val;
                this.handleSessionChange();
              }
            "
          >
            <a-select-option value="">
              {{ $t("error.selectSeance") }}
            </a-select-option>
            <a-select-option
              v-for="session in sessionList"
              :key="session._id"
              :value="session._id"
            >
              {{ session.subject.name }}
            </a-select-option>
          </a-select>

          <div>
            <a-button
              type="primary"
              style="margin-right: 5px"
              :loading="printLoading"
              :disabled="printLoading"
              @click="generatePdf"
              v-if="selectedClasse && selectedSession"
            >
              <a-icon v-if="!printLoading" type="file-pdf" />{{
                $t("action.imprimer")
              }}
            </a-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <div
            class="card"
            style="margin-top: 10px"
            v-if="selectedClasse && selectedSession"
          >
            <div class="data-table">
              <a-table
                @change="tableChanged"
                :loading="tableLoading"
                :pagination="true"
                :data-source="activeData"
                :columns="columns"
              >
                <template slot="mark" slot-scope="text, record">
                  <div v-if="!moment().isSame(selectedDate, 'day')">
                    <a-tag
                      :color="
                        {
                          Retard: 'orange',
                          Présent: 'green',
                          Absence: 'red',
                        }[getStudentAttendance(record._id)]
                      "
                      >{{
                        getStudentAttendance(record._id) == "Retard"
                          ? $t("assiduite.retard")
                          : getStudentAttendance(record._id) == "Absence"
                          ? $t("assiduite.absence")
                          : $t("assiduite.present")
                      }}</a-tag
                    >
                  </div>

                  <attendance
                    v-else
                    :student="record._id"
                    :schedule="selectedSession"
                    :type="getStudentAttendance(record._id)"
                    :id="getStudentAttendanceId(record._id)"
                    :date="selectedDate"
                  />
                </template>

                <template slot="name" slot-scope="text, record">
                  <span>{{ record.fullName }}</span>
                </template>

                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`${$t('scolarite.recherche')} ${
                      column.title
                    }`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                    >{{ $t("action.imprimer") }}</a-button
                  >
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                    >{{ $t("action.reinitialiser") }}</a-button
                  >
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
              </a-table>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card" v-if="selectedClasse">
            <attendanceChart
              :data="attendanceChartData"
              :className="selectedClassName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import attendance from "./components/attendance";
import attendanceChart from "./components/attendanceChart";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import moment from "moment";

export default {
  computed: mapState(["settings", "user"]),
  components: {
    attendance: attendance,
    attendanceChart,
  },
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.filtredTable = this.activeData;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });
  },
  data() {
    return {
      printLoading: false,
      schoolType: null,
      filtredTable: [],
      selectedSession: this.$t("emploi.seanceListe"),
      selectedDate: "",
      selectedStudent: "",
      selectedStudentName: "",
      selectedSessionName: "",
      studentLoading: false,
      sessionLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      activeData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      sessionList: [],
      attendanceChartData: [],
      attendanceData: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("assiduite.assiduite"),
          dataIndex: "mark",
          scopedSlots: { customRender: "mark" },
        },
      ],
      listModules: [],
      selectedLevel: null,
      selectedClassName: "",
    };
  },
  methods: {
    moment,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async handleClassChange(on, value) {
      this.selectedSession = null;
      this.sessionList = [];
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      this.selectedDate = new Date();
      this.handleDateChange();
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });

      apiClient
        .post("/attendance/filter", {
          query: {},
        })
        .then(({ data }) => {
          this.attendanceChartData = data.filter(
            (s) => s.schedule && s.schedule.classRoomId == this.selectedClasse
          );
        });
    },

    async handleDateChange() {
      this.sessionList = [];
      this.sessionLoading = true;
      const query = {
        classeRoom: this.selectedClasse,
        day: (moment(this.selectedDate).isoWeekday() - 1).toString(),
        status: { $ne: "inactive" },
      };

      apiClient
        .post("/schedules/filter", {
          query: query,
          aggregation: [
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $lookup: {
                from: "modules",
                localField: "module",
                foreignField: "_id",
                as: "module",
              },
            },
            {
              $lookup: {
                from: "modulespecifics",
                localField: "moduleSpecific",
                foreignField: "_id",
                as: "moduleSpecific",
              },
            },
            {
              $project: {
                _id: 1,
                time: 1,
                day: 1,
                subject: {
                  $let: {
                    vars: {
                      subjectVar: {
                        $arrayElemAt: ["$subject", 0],
                      },
                    },
                    in: {
                      name: "$$subjectVar.name",
                      _id: "$$subjectVar._id",
                      status: "$$subjectVar.status",
                    },
                  },
                },
                module: {
                  $let: {
                    vars: {
                      moduleVar: {
                        $arrayElemAt: ["$module", 0],
                      },
                    },
                    in: {
                      name: "$$moduleVar.name",
                      _id: "$$moduleVar._id",
                      status: "$$moduleVar.status",
                    },
                  },
                },
                moduleSpecific: {
                  $let: {
                    vars: {
                      moduleSpecificVar: {
                        $arrayElemAt: ["$moduleSpecific", 0],
                      },
                    },
                    in: {
                      name: "$$moduleSpecificVar.name",
                      _id: "$$moduleSpecificVar._id",
                      status: "$$moduleSpecificVar.status",
                    },
                  },
                },
                ends: 1,
                duration: 1,
                type: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.sessionList = [];
          data.map((s) => {
            if (!this.sessionList.find((d) => d._id == s._id)) {
              if (s.type == "MP") s.subject = s.module;
              if (s.type == "MS") s.subject = s.moduleSpecific;

              this.sessionList.push(s);
            }
          });

          this.sessionList = this.sessionList.filter(
            (d) => d.subject.status !== "inactive"
          );
          this.sessionLoading = false;
          this.selectedSession = "";
        })
        .catch((e) => console.log(e));
    },

    async handleSessionChange() {
      this.tableLoading = true;
      this.selectedSessionName = this.sessionList.find(
        (s) => s._id == this.selectedSession
      ).subject.name;
      const todayDate = moment(this.selectedDate).format("YYYY-MM-DD");
      const tomorrowDate = moment(this.selectedDate)
        .add(1, "d")
        .format("YYYY-MM-DD");
      apiClient
        .post("/attendance/filter", {
          query: {
            schedule: this.selectedSession,
            date: {
              $gte: todayDate,
              $lt: tomorrowDate,
            },
          },
        })
        .then(({ data }) => {
          this.attendanceData = data;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    getStudentAttendance(studentID) {
      const attend = this.attendanceData.find((at) => at.student == studentID);
      if (attend) return attend.type;
      return "Présent";
    },

    getStudentAttendanceId(studentID) {
      const attend = this.attendanceData.find((at) => at.student == studentID);
      if (attend) return attend._id;
      return null;
    },

    async generatePdf() {
      this.$gtag.event("Imp assiduités", {
        event_category: "Impression PDF",
        event_label: "Assiduités section",
        value: 1,
      });

      this.printLoading = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      var date = new Date(this.selectedDate);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(20);
      let width = doc.getTextWidth(this.selectedClassName);
      doc.text(pdf_width / 2 - width / 2, 37, this.selectedClassName);
      doc.setFontSize(18);
      width = doc.getTextWidth(this.selectedSessionName);
      doc.text(pdf_width / 2 - width / 2, 45, this.selectedSessionName);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 6, 6, 30, 30);
      doc.setFontSize(12);
      doc.text(
        130,
        10,
        "Feuille d'appel : " + date.toLocaleDateString("fr-FR", options)
      );
      var arr = [];
      const array = [];
      var counter = 1;
      for (let i = 0; i < this.activeData.length; i++) {
        arr.push(counter);

        arr.push(this.activeData[i].fullName);
        arr.push(this.getStudentAttendance(this.activeData[i]._id));
        array.push(arr);
        arr = [];
        counter++;
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        styles: { font: "Amiri" },
        margin: { top: 50 },
        head: [["Num", "Nom de l'élève", "Assiduité"]],
        body: array,
        foot: [],
      });

      this.printLoading = false;
      doc.save(
        this.selectedClassName +
          " - " +
          this.selectedSessionName +
          " - " +
          date.toLocaleDateString("fr-FR", options)
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}
</style>
