<template>
  <div>
    <a-radio-group v-model="currentType" fill="#999999">
      <div class="row my-2">
        <div class="col-4 green">
          <a-radio v-if="currentType == 'Présent'" :value="'Présent'">{{ $t("assiduite.present") }}</a-radio>
          <a-popconfirm v-else :title="$t('assiduite.areYouSureToEdit')" @confirm="() => {
            currentType = 'Présent';
            changeType()
          }">
            <a-radio :value="'Présent'">{{ $t("assiduite.present") }}</a-radio>
          </a-popconfirm>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-4 orange">
          <a-radio v-if="currentType == 'Retard'" :value="'Retard'">{{ $t("assiduite.retard") }}</a-radio>
          <a-popconfirm v-else :title="$t('assiduite.areYouSureToEdit')" @confirm="() => {
            currentType = 'Retard';
            changeType()
          }">
            <a-radio :value="'Retard'">{{ $t("assiduite.retard") }}</a-radio>
          </a-popconfirm>

        </div>
      </div>
      <div class="row my-2">
        <div class="col-4 red">
          <a-radio v-if="currentType == 'Absence'" :value="'Absence'">{{ $t("assiduite.absence") }}</a-radio>
          <a-popconfirm v-else :title="$t('assiduite.areYouSureToEdit')" @confirm="() => {
            currentType = 'Absence';
            changeType()
          }">
            <a-radio :value="'Absence'">{{ $t("assiduite.absence") }}</a-radio>
          </a-popconfirm>
        </div>
      </div>
    </a-radio-group>
  </div>
</template>
<script>
//  @change="changeType"
import apiClient from "@/services/axios";
import moment from "moment";

export default {
  name: "sAttendance",
  components: {},
  props: ["id", "schedule", "student", "type", "date"],

  methods: {
    moment,
    changeType: function () {
      if (this.attendanceId) {
        if (this.currentType == "Présent") {
          apiClient.delete("/attendance/" + this.attendanceId).then(() => {
            this.$message.success(this.$t("assiduite.assiduiteMSA"));
          });
        } else {
          apiClient
            .patch("/attendance/" + this.attendanceId, {
              data: {
                type: this.currentType,
              },
            })
            .then(() => {
              this.$message.success(this.$t("assiduite.assiduiteMSA"));
            });
        }
      } else {
        apiClient
          .put("/attendance", {
            data: {
              student: this.student,
              type: this.currentType,
              schedule: this.schedule,
              date: moment(this.date),
            },
          })
          .then(({ data }) => {
            this.attendanceId = data._id;
            this.$message.success(this.$t("assiduite.assiduiteMSA"));
          });
      }
    },
  },

  data() {
    return {
      attendanceId: null,
      currentType: "",
    };
  },

  watch: {
    schedule: function () {
      this.attendanceId = this.id;
      this.currentType = this.type;
    },
    type: function () {
      this.attendanceId = this.id;
      this.currentType = this.type;
    },
    id: function () {
      this.attendanceId = this.id;
      this.currentType = this.type;
    },
    student: function () {
      this.attendanceId = this.id;
      this.currentType = this.type;
    },
  },

  created() {
    const type = this.type;
    this.attendanceId = this.id;
    this.currentType = type;
  },
};
</script>

<style scoped>
.green ::v-deep .ant-radio-inner::after {
  background-color: #67c23a !important;
}

.green ::v-deep .ant-radio-checked .ant-radio-inner {
  border-color: #67c23a;
}

.orange ::v-deep .ant-radio-inner::after {
  background-color: #e6a23c !important;
}

.orange ::v-deep .ant-radio-checked .ant-radio-inner {
  border-color: #e6a23c;
}

.red ::v-deep .ant-radio-inner::after {
  background-color: #f56c6c !important;
}

.red ::v-deep .ant-radio-checked .ant-radio-inner {
  border-color: #f56c6c;
}
</style>
