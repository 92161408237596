<template>
  <div>
    <div style="margin: 15px">
      <h5>{{ $t("assiduite.assiduiteMoisChart", { className }) }}</h5>
    </div>

    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="300"
      type="area"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["data", "className"],
  data() {
    return {
      options: {},
      series: [],
      labelColor: "#3F4254",
      colors: ["#D9214E", "#67c23a", "#D9214E"],
      lightColors: ["#F1FAFF", "#e8fff3", "#F1FAFF"],
    };
  },

  watch: {
    data: function (value) {
      this.updateChart();
    },

    className: function (value) {
      this.updateChart();
    },
  },

  created() {
    this.updateChart();
  },

  methods: {
    updateChart() {
      const attendance = this.data;
      const currentDate = new Date();
      const today = new Date().getDate();
      const monthName = moment().format("MMMM");

      const currentMonthAttendance = attendance.filter((att) => {
        const attDate = new Date(att.date);
        return (
          attDate.getMonth() == currentDate.getMonth() &&
          attDate.getFullYear() == currentDate.getFullYear() &&
          attDate.getDate() <= today
        );
      });

      const days = [];
      for (let i = 1; i <= today; i++) days.push(i + " " + monthName);

      const absentSerie = [];
      const delaySerie = [];
      for (let i = 1; i <= today; i++) {
        let a = 0;
        let d = 0;
        for (const att of currentMonthAttendance) {
          if (new Date(att.date).getDate() == i)
            if (att.type == "Retard") d++;
            else if (att.type == "Absence") a++;
        }
        absentSerie.push(a);
        delaySerie.push(d);
      }
      this.updateOptions(days);
      this.updateSeries(delaySerie, absentSerie);
    },

    updateSeries(delaySerie, absentSerie) {
      this.series = [
        {
          name: "Retard",
          data: delaySerie,
          color: "#ffc107",
        },
        {
          name: "Absence",
          data: absentSerie,
          color: "#D9214E",
        },
      ];
    },
    updateOptions(days) {
      this.options = {
        chart: {
          height: 300,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          color: this.colors,
        },
        xaxis: {
          type: "string",
          categories: days,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {},
        },
        fill: {
          type: "gradient",
          gradient: {
            stops: [0, 100],
          },
        },
        color: this.colors,
        markers: {
          color: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      };
    },
  },
};
</script>
